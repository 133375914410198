<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.matter_type.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.matter_type.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.matter_type.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="matter_type"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.matter_type.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteMatterType"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "MatterTypeForm",
  mixins: [validationMixin],
  components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    MatterType: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          school: this.institution_id,
          active: true,
          updated_by: null,
          update_date: null,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matter_type: this.MatterType
        ? {
            id: this.MatterType.id,
            name: this.MatterType.name,
            description: this.MatterType.description,
            updated_by: this.MatterType.updated_by,
            update_date: this.MatterType.update_date,
          }
        : { id: generateUniqueId(), name: "" },
    };
  },
  validations: {
    matter_type: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.matter_type[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.matter_type.$touch();
      if (this.$v.matter_type.$anyError) {
        return;
      }
      if (isNaN(this.matter_type.id)) {
        this.createMatterType();
      } else this.updateMatterType();
    },
    createMatterType() {
      this.matter_type.school = this.institution_id;
      this.$store
        .dispatch(
          names.POST_MATTER_TYPE,
          this.matter_type,
          this.matter_type.school
        )
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.mattertype",
                false,
                "Tipo De Asignatura"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateMatterType() {
      this.matter_type.school = this.institution_id;
      this.$store
        .dispatch(
          names.UPDATE_MATTER_TYPE,
          this.matter_type,
          this.matter_type.school
        )
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.mattertype",
                false,
                "Tipo De Asignatura"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteMatterType() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.mattertype",
          false,
          "Tipo De Asignatura"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_MATTER_TYPE, this.matter_type.id);
          this.$emit("deleted", this.matter_type);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>